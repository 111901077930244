import React, { useState, useEffect, useRef } from 'react';
import api from '../apiConfig/api';
import * as XLSX from 'xlsx';

const ReportDownloadPage = () => {
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState('');
  const [initialWards, setInitialWards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [selectedOrg, setSelectedOrg] = useState('');
  const [selectedWards, setSelectedWards] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showWardDropdown, setShowWardDropdown] = useState(false);
  const dropdownRef = useRef(null);


  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const orgCode = localStorage.getItem('org_code');
        
        // Keep CMA flow as is
        if (orgCode === "CMA") {
          const response = await api.get('https://ras-tndma.in/api/prod/user/all-org');
          if (response.data && response.data.data) {
            const orgs = response.data.data.map(org => ({
              org_name: org.org_name,
              total: org.total,
              completed: org.completed,
              pending: org.pending
            }));
            setOrganizations(orgs);
          }
        } else {
          // For non-CMA users
          const orgName = localStorage.getItem('organization_name');
          const wardDetailsStr = localStorage.getItem('ward_details');
          const token = localStorage.getItem('token');
          
          if (orgName) {
            setOrganization(orgName);
            setSelectedOrg(orgName);
    
            if (!wardDetailsStr) {
              // If no ward details in localStorage, fetch from API
              try {
                const response = await api.get(`https://ras-tndma.in/api/prod/user/asst_smry`, {
                  params: {
                    org: orgName
                  },
                  headers: {
                    'Authorization': `Bearer ${token}`
                  }
                });
    
                if (response.data?.data) {
                  const transformedWards = response.data.data.map(ward => ({
                    WardID: ward.ward_id || ward.id || String(Math.random()),
                    WarDName: ward.ward_name
                  }));
                  setInitialWards(transformedWards);
                }
              } catch (err) {
                console.error('Error fetching wards from API:', err);
                setInitialWards([]);
              }
            } else {
              // Use ward details from localStorage
              try {
                const wardDetails = JSON.parse(wardDetailsStr);
                const transformedWards = wardDetails.map(ward => ({
                  WardID: ward.wardId.toString(),
                  WarDName: ward.wardName
                }));
                setInitialWards(transformedWards);
              } catch (err) {
                console.error('Error parsing ward details:', err);
                setInitialWards([]);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError('Failed to load organization data');
      } finally {
        setIsLoading(false);
      }
    };
    fetchInitialData();
}, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowWardDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchWardsByOrg = async (orgName) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('final'); // Get token
      const encodedOrgName = encodeURIComponent(orgName);
      
      // Get wards for the selected organization with token
      const response = await api.get(`/asset/org/${encodedOrgName}`, {
        headers: {
          'Authorization': `Bearer ${token}`  // Add token to headers
        }
      });
      
      if (response.data?.data?.[0]?.wards) {
        const transformedWards = response.data.data[0].wards.map((wardName, index) => ({
          WardID: index.toString(),
          WarDName: wardName
        }));
        setInitialWards(transformedWards);
        setSelectedWards([]);  // Clear any previously selected wards
      }
    } catch (error) {
      console.error('Error fetching wards:', error);
      setError('Failed to load wards');
      setInitialWards([]);
    } finally {
      setLoading(false);
    }
  };
  const handleOrgChange = async (e) => {
    const selectedOrgName = e.target.value;
    setSelectedOrg(selectedOrgName);
    setSelectedWards([]); // Clear selected wards when organization changes
    
    // Always fetch wards when organization is selected
    if (selectedOrgName) {
      await fetchWardsByOrg(selectedOrgName);
    }
  };
  const fetchReportData = async () => {
    if (!selectedOrg || selectedWards.length === 0) return;
  
    setLoading(true);
    setError(null);
  
    try {
      const orgCode = localStorage.getItem('org_code');
      const token = localStorage.getItem('token'); // Get token from localStorage
  
      if (orgCode === "CMA") {
        const wardsParam = selectedWards.join(', ');
        const endpoint = `/survey/report/${filter.toLowerCase()}`;
  
        const response = await api.get(endpoint, {
          params: {
            org: selectedOrg,
            ward: wardsParam
          }
        });
  
        if (response.data && !response.data.error) {
          setReportData(response.data.data || []);
        } else {
          throw new Error(response.data.message || 'Failed to fetch data');
        }
      } else {
        const formattedWards = selectedWards.join(',');
        const response = await api.get(`https://ras-tndma.in/api/prod/survey/report/all`, {
          params: {
            org: selectedOrg,
            ward: formattedWards
          },
          headers: {
            'Authorization': `Bearer ${token}` // Add token to headers
          }
        });
  
        if (response.data && !response.data.error) {
          setReportData(response.data.data || []);
        } else {
          throw new Error(response.data.message || 'Failed to fetch data');
        }
      }
    } catch (err) {
      console.error('API Error:', err);
      setError(err.message || 'Failed to fetch report data');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedOrg && selectedWards.length > 0) {
      fetchReportData();
    }
  }, [selectedOrg, selectedWards, filter]);

  const handleWardToggle = (wardName) => {
    console.log('Toggling ward:', wardName); // Debug log
    setSelectedWards(prev => {
      if (prev.includes(wardName)) {
        return prev.filter(w => w !== wardName);
      } else {
        return [...prev, wardName];
      }
    });
  };
  
  const handleSelectAllWards = () => {
    if (selectedWards.length === initialWards.length) {
      setSelectedWards([]);
    } else {
      const allWardNames = initialWards.map(ward => ward.WarDName);
      setSelectedWards(allWardNames);
    }
  };

  const handleExportToExcel = async () => {
    if (!selectedOrg || selectedWards.length === 0) return;
  
    try {
      setLoading(true);
      const endpoint = `/survey/report/data/${filter.toLowerCase()}`;
      
      const response = await api.get(endpoint, {
        params: {
          org: selectedOrg,
          ward: selectedWards.join(',')
        }
      });
  
      const blobData = await response.data;
      const reader = new FileReader();
  
      reader.onload = async () => {
        try {
          const jsonData = JSON.parse(reader.result);
          
          if (!jsonData.data || !Array.isArray(jsonData.data)) {
            throw new Error('Invalid data format received');
          }
  
          const ws = XLSX.utils.json_to_sheet(jsonData.data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Survey Data');
  
          const columnWidths = Object.keys(jsonData.data[0] || {}).map(() => ({ wch: 20 }));
          ws['!cols'] = columnWidths;
  
          const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
          const blob = new Blob([excelBuffer], { 
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
  
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          const currentDate = new Date().toISOString().split('T')[0];
          const filename = `survey_report_${currentDate}_${filter.toLowerCase()}.xlsx`;
          
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          setError(null);
        } catch (error) {
          console.error('Error processing data:', error);
          setError('Failed to process report data');
        }
      };
  
      reader.onerror = () => {
        setError('Failed to read response data');
      };
  
      reader.readAsText(blobData);
    } catch (error) {
      console.error('Excel Export Error:', error);
      setError(error.message || 'Failed to download report. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const renderTableHeaders = () => {
    const headerClasses = "px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider";
    const rightAlignedHeader = "px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider";
  
    switch (filter) {
      case 'Completed':
        return (
          <tr>
            <th className={headerClasses}>Organization</th>
            <th className={headerClasses}>Zone</th>
            <th className={headerClasses}>Ward</th>
            <th className={rightAlignedHeader}>Total</th>
            <th className={rightAlignedHeader}>Completed</th>
            <th className={rightAlignedHeader}>Variations</th>
          </tr>
        );
      case 'Pending':
        return (
          <tr>
            <th className={headerClasses}>Organization</th>
            <th className={headerClasses}>Zone</th>
            <th className={headerClasses}>Ward</th>
            <th className={rightAlignedHeader}>Total</th>
            <th className={rightAlignedHeader}>Pending</th>
          </tr>
        );
      case 'Variations':
        return (
          <tr>
            <th className={headerClasses}>Organization</th>
            <th className={headerClasses}>Zone</th>
            <th className={headerClasses}>Ward</th>
            <th className={rightAlignedHeader}>Total</th>
            <th className={rightAlignedHeader}>Completed</th>
            <th className={rightAlignedHeader}>Variations by Usage</th>
            <th className={rightAlignedHeader}>Variations by Area</th>
            <th className={rightAlignedHeader}>Variations by Both</th>
          </tr>
        );
      default:
        return (
          <tr>
            <th className={headerClasses}>Organization</th>
            <th className={headerClasses}>Zone</th>
            <th className={headerClasses}>Ward</th>
            <th className={rightAlignedHeader}>Completed</th>
            <th className={rightAlignedHeader}>Pending</th>
            <th className={rightAlignedHeader}>Total</th>
          </tr>
        );
    }
  };
  
  const renderTableRow = (report, index) => {
    const cellClasses = "px-4 py-3 whitespace-nowrap text-sm text-gray-900";
    const numberCellClasses = "px-4 py-3 whitespace-nowrap text-sm text-gray-900 text-right";
  
    switch (filter) {
      case 'Completed':
        return (
          <tr key={`${report.ward_name}-${index}`} className="hover:bg-gray-50">
            <td className={cellClasses}>{report.org_name}</td>
            <td className={cellClasses}>{report.zone_name}</td>
            <td className={cellClasses}>{report.ward_name}</td>
            <td className={numberCellClasses}>{report.total}</td>
            <td className={numberCellClasses}>{report.completed}</td>
            <td className={numberCellClasses}>
              {report.variations || report.usage_vary || '0'}
            </td>
          </tr>
        );
      case 'Variations':
        return (
          <tr key={`${report.ward_name}-${index}`} className="hover:bg-gray-50">
            <td className={cellClasses}>{report.org_name}</td>
            <td className={cellClasses}>{report.zone_name}</td>
            <td className={cellClasses}>{report.ward_name}</td>
            <td className={numberCellClasses}>{report.total}</td>
            <td className={numberCellClasses}>{report.completed}</td>
            <td className={numberCellClasses}>{report.usage_vary || '0'}</td>
            <td className={numberCellClasses}>{report.area_vary || '0'}</td>
            <td className={numberCellClasses}>{report.both_vary || '0'}</td>
          </tr>
        );
      case 'Pending':
        return (
          <tr key={`${report.ward_name}-${index}`} className="hover:bg-gray-50">
            <td className={cellClasses}>{report.org_name}</td>
            <td className={cellClasses}>{report.zone_name}</td>
            <td className={cellClasses}>{report.ward_name}</td>
            <td className={numberCellClasses}>{report.total}</td>
            <td className={numberCellClasses}>{report.pending}</td>
          </tr>
        );
      default:
        return (
          <tr key={`${report.ward_name}-${index}`} className="hover:bg-gray-50">
            <td className={cellClasses}>{report.org_name}</td>
            <td className={cellClasses}>{report.zone_name}</td>
            <td className={cellClasses}>{report.ward_name}</td>
            <td className={numberCellClasses}>{report.completed}</td>
            <td className={numberCellClasses}>{report.pending}</td>
            <td className={numberCellClasses}>{report.total}</td>
          </tr>
        );
    }
  };
  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden isolation-auto">
        <div className="px-6 py-4 bg-[#75d1e3] text-black">
          <div className="flex flex-wrap gap-4 items-end justify-between relative">
            <div className="flex flex-wrap gap-4">
              <div className="relative">
                <label className="block text-sm font-medium mb-1">Organization</label>
                <select
  value={selectedOrg}
  onChange={handleOrgChange}
  className="px-3 py-2 bg-white rounded-md w-64 text-gray-700 border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
>
  <option value="">Select Organization</option>
  {localStorage.getItem('org_code') === "CMA"
    ? organizations.map((org, index) => (
        <option key={index} value={org.org_name}>
          {org.org_name}
        </option>
      ))
    : <option value={organization}>{organization}</option>
  }
</select>
              </div>

              <div className="relative" ref={dropdownRef}>
                <label className="block text-sm font-medium mb-1">Ward</label>
                <button
                  type="button"
                  onClick={() => setShowWardDropdown(!showWardDropdown)}
                  className="px-3 py-2 bg-white rounded-md w-64 text-gray-700 text-left flex justify-between items-center border border-gray-300 hover:border-blue-500 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                >
                  <span className="truncate">
                    {selectedWards.length 
                      ? `${selectedWards.length} ward${selectedWards.length > 1 ? 's' : ''} selected`
                      : 'Select Wards'}
                  </span>
                  <svg 
                    className={`w-4 h-4 transition-transform duration-200 ${showWardDropdown ? 'transform rotate-180' : ''}`} 
                    fill="currentColor" 
                    viewBox="0 0 20 20"
                  >
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>

                {showWardDropdown && (
                  <div 
                    className="fixed inset-0"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      pointerEvents: 'none',
                      zIndex: 40
                    }}
                  >
                    <div
                      className="absolute bg-white rounded-md shadow-xl border border-gray-200"
                      style={{
                        top: dropdownRef.current?.getBoundingClientRect().bottom + window.scrollY + 4,
                        left: dropdownRef.current?.getBoundingClientRect().left,
                        width: dropdownRef.current?.offsetWidth,
                        pointerEvents: 'auto'
                      }}
                    >
                      <div className="sticky top-0 bg-white border-b border-gray-200 p-2">
                        <label className="flex items-center px-2 py-1 hover:bg-gray-50 rounded cursor-pointer">
                          <input
                            type="checkbox"
                            checked={selectedWards.length === initialWards.length}
                            onChange={handleSelectAllWards}
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                          <span className="ml-2 text-sm">Select All Wards</span>
                        </label>
                      </div>
                      <div className="max-h-60 overflow-y-auto">
                        {initialWards.map((ward) => (
                          <label 
                            key={ward.WardID} 
                            className="flex items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              checked={selectedWards.includes(ward.WarDName)}
                              onChange={() => handleWardToggle(ward.WarDName)}
                              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                            />
                            <span className="ml-2 text-sm">{ward.WarDName}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Filter</label>
                <select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className="px-3 py-2 bg-white rounded-md w-64 text-gray-700 border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                >
                  <option value="all">All</option>
                  <option value="Completed">Completed</option>
                  <option value="Pending">Pending</option>
                  <option value="Variations">Variations</option>
                </select>
              </div>
            </div>

            <button 
              onClick={handleExportToExcel}
              disabled={loading || !selectedOrg || selectedWards.length === 0}
              className="flex items-center px-4 py-2 bg-white text-black rounded-md hover:bg-gray-50 transition-colors disabled:opacity-50 disabled:cursor-not-allowed border border-gray-300"
            >
              <svg className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10 12V3c0-.55-.45-1-1-1s-1 .45-1 1v9l-3.5-3.5c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l5.2 5.2c.39.39 1.02.39 1.41 0l5.2-5.2c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L10 12z"/>
              </svg>
              {loading ? 'Exporting...' : 'Export to Excel'}
            </button>
          </div>
        </div>

        <div className="px-6 py-4 relative z-10">
          {error && (
            <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-600 rounded-md">
              <div className="flex items-center">
                <svg className="h-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd"/>
                </svg>
                {error}
              </div>
            </div>
          )}

          {loading && (
            <div className="flex justify-center items-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
          )}

          {!loading && reportData.length > 0 && (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  {renderTableHeaders()}
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {reportData.map((report, index) => renderTableRow(report, index))}
                </tbody>
              </table>
            </div>
          )}

          {!loading && reportData.length === 0 && (
            <div className="text-center py-8">
              <svg 
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                />
              </svg>
              <h3 className="mt-2 text-sm font-medium text-gray-900">No data available</h3>
              <p className="mt-1 text-sm text-gray-500">
                Select an organization and at least one ward to view the report data.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportDownloadPage;