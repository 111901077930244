import React, { useState } from 'react';
import { ChevronDown, ChevronRight, Search, ArrowUpDown, ArrowUp, ArrowDown, X, AlertCircle, ChevronRightIcon } from 'lucide-react';
import api from '../apiConfig/api';

// Reusable components
const LoadingRows = () => (
  <div className="p-4 animate-pulse space-y-3">
    {[1, 2, 3].map(i => (
      <div key={i} className="grid grid-cols-5 gap-4">
        <div className="h-4 bg-gray-200 rounded col-span-2"></div>
        <div className="h-4 bg-gray-200 rounded"></div>
        <div className="h-4 bg-gray-200 rounded"></div>
        <div className="h-4 bg-gray-200 rounded"></div>
      </div>
    ))}
  </div>
);

const AssetDetailsModal = ({ isOpen, onClose, assetDetails, isLoading, wardInfo, onAssetClick }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-3xl max-h-[90vh] flex flex-col">
        <div className="p-4 border-b border-gray-200 flex items-center justify-between sticky top-0 bg-white">
          <h2 className="text-lg font-semibold text-gray-800">
            Asset Details - {wardInfo?.ward_name || 'N/A'} - Survey Completed
          </h2>
          <button 
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>
        <div className="overflow-auto p-4">
          {isLoading ? (
            <LoadingRows />
          ) : assetDetails.length > 0 ? (
            <div className="grid gap-4">
              {assetDetails.map((asset, index) => (
                <div 
                  key={index} 
                  onClick={() => onAssetClick(asset.id)}
                  className="border border-gray-200 rounded-lg p-4 hover:shadow-lg hover:border-gray-300 transition-all cursor-pointer"
                >
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500">Street Name</p>
                      <p className="font-medium">{asset.street_name || 'N/A'}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Door No</p>
                      <p className="font-medium">{asset.new_door || 'N/A'}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Owner Name</p>
                      <p className="font-medium">{asset.owner || 'N/A'}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Usage</p>
                      <p className={`font-medium ${
                        asset.usage?.toLowerCase() === 'commercial' || 
                        asset.usage?.toLowerCase() === 'mixed' 
                          ? 'text-red-600' 
                          : 'text-gray-800'
                      }`}>
                        {asset.usage || 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 flex justify-end text-sm text-blue-600">
                    View Details <ChevronRight className="h-4 w-4" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-8 text-gray-500">
              No asset details available
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const OrgSummaryTable = ({ data, isLoading, onNavigateToReport }) => {
  const [expandedOrg, setExpandedOrg] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [orgDetails, setOrgDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: null
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWardInfo, setSelectedWardInfo] = useState(null);
  const [assetDetails, setAssetDetails] = useState([]);
  const [isLoadingAssetDetails, setIsLoadingAssetDetails] = useState(false);

  const groupedData = data.reduce((acc, item) => {
    if (!acc[item.org_name]) {
      acc[item.org_name] = {
        total: 0,
        completed: 0,
        pending: 0
      };
    }
    acc[item.org_name].total += parseInt(item.total || 0);
    acc[item.org_name].completed += parseInt(item.completed || 0);
    acc[item.org_name].pending += parseInt(item.pending || 0);
    return acc;
  }, {});

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleCompletedClick = async (detail, e) => {
    e.stopPropagation(); // Prevent triggering parent click events
    setSelectedWardInfo(detail);
    setIsModalOpen(true);
    setIsLoadingAssetDetails(true);

    try {
      const response = await api.get('/asset/report', {
        params: {
          org: encodeURIComponent(detail.org_name),
          zone: encodeURIComponent(detail.zone_name),
          ward: encodeURIComponent(detail.ward_name)
        }
      });
      setAssetDetails(response.data.data || []);
    } catch (error) {
      console.error('Error fetching asset details:', error);
      setAssetDetails([]);
    } finally {
      setIsLoadingAssetDetails(false);
    }
  };

  const handleAssetClick = (assetId) => {
    if (!assetId) return;
    onNavigateToReport(assetId);
  };

  const filteredOrgs = Object.entries(groupedData)
    .filter(([orgName]) => 
      orgName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (!sortConfig.key) return 0;
      const aValue = a[1][sortConfig.key] || 0;
      const bValue = b[1][sortConfig.key] || 0;
      return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
    });

  const handleOrgClick = async (orgName) => {
    if (expandedOrg === orgName) {
      setExpandedOrg(null);
      setOrgDetails([]);
      return;
    }

    setExpandedOrg(orgName);
    setDetailsLoading(true);
    try {
      const response = await api.get(`/user/asst_smry?org=${encodeURIComponent(orgName)}`);
      setOrgDetails(response.data.data || []);
    } catch (error) {
      console.error('Error fetching organization details:', error);
      setOrgDetails([]);
    } finally {
      setDetailsLoading(false);
    }
  };

  const SortIcon = ({ field }) => {
    if (sortConfig.key !== field) return <ArrowUpDown className="h-4 w-4" />;
    return sortConfig.direction === 'asc' ? 
      <ArrowUp className="h-4 w-4" /> : 
      <ArrowDown className="h-4 w-4" />;
  };

  if (isLoading) {
    return (
      <div className="space-y-4 animate-pulse">
        {[1, 2, 3].map(i => (
          <div key={i} className="bg-white p-4 rounded-lg shadow-sm">
            <div className="h-6 bg-gray-200 rounded w-1/3 mb-4"></div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              <div className="h-4 bg-gray-200 rounded"></div>
              <div className="h-4 bg-gray-200 rounded"></div>
              <div className="h-4 bg-gray-200 rounded hidden md:block"></div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      <div className="p-4 md:p-6">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4 mb-6">
          <div className="flex flex-col md:flex-row md:items-center gap-4">
            <h2 className="text-lg font-semibold text-gray-800">Organization Summary</h2>
            <div className="flex gap-2">
              <button
                onClick={() => handleSort('completed')}
                className="flex items-center gap-1 px-3 py-1.5 text-sm border rounded-lg hover:bg-gray-50"
              >
                Completed <SortIcon field="completed" />
              </button>
              <button
                onClick={() => handleSort('pending')}
                className="flex items-center gap-1 px-3 py-1.5 text-sm border rounded-lg hover:bg-gray-50"
              >
                Pending <SortIcon field="pending" />
              </button>
              <button
                onClick={() => handleSort('total')}
                className="flex items-center gap-1 px-3 py-1.5 text-sm border rounded-lg hover:bg-gray-50"
              >
                Total <SortIcon field="total" />
              </button>
            </div>
          </div>
          <div className="relative w-full md:w-auto">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search organizations..."
              className="w-full md:w-64 pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <Search className="h-5 w-5 text-gray-400 absolute left-3 top-2.5" />
          </div>
        </div>

        {filteredOrgs.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            No organizations found matching "{searchTerm}"
          </div>
        ) : (
          <div className="divide-y divide-gray-200">
            {filteredOrgs.map(([orgName, orgData]) => (
              <div key={orgName} className="space-y-3">
                <button
                  onClick={() => handleOrgClick(orgName)}
                  className="w-full flex flex-col md:flex-row md:items-center justify-between py-4 hover:bg-gray-50 transition-colors gap-2 md:gap-4"
                >
                  <div className="flex items-center gap-2">
                    {expandedOrg === orgName ? (
                      <ChevronDown className="h-5 w-5 text-gray-500 flex-shrink-0" />
                    ) : (
                      <ChevronRight className="h-5 w-5 text-gray-500 flex-shrink-0" />
                    )}
                    <h3 className="font-medium text-gray-900 text-left">{orgName}</h3>
                  </div>
                  <div className="flex items-center gap-4 pl-7 md:pl-0">
                    <div className="text-sm">
                      <span className="text-gray-500 mr-2">Completed:</span>
                      <span className="text-green-600 font-medium">{orgData.completed}</span>
                    </div>
                    <div className="text-sm">
                      <span className="text-gray-500 mr-2">Pending:</span>
                      <span className="text-blue-600 font-medium">{orgData.pending}</span>
                    </div>
                    <div className="text-sm">
                      <span className="text-gray-500 mr-2">Total:</span>
                      <span className="text-gray-900 font-medium">{orgData.total}</span>
                    </div>
                  </div>
                </button>

                {expandedOrg === orgName && (
                  <div className="pl-4 md:pl-8 pb-4 overflow-x-auto">
                    <div className="bg-gray-50 rounded-lg overflow-hidden min-w-[640px]">
                      {detailsLoading ? (
                        <LoadingRows />
                      ) : (
                        <>
                          <div className="grid grid-cols-5 gap-4 py-2 px-4 bg-gray-100 text-sm font-medium text-gray-500">
                            <div>Zone Name</div>
                            <div>Ward Name</div>
                            <div className="text-center">Completed</div>
                            <div className="text-center">Pending</div>
                            <div className="text-center">Total</div>
                          </div>
                          <div className="divide-y divide-gray-200">
                            {orgDetails.map((detail, index) => (
                              <div key={index} className="grid grid-cols-5 gap-4 py-3 px-4 text-sm">
                                <div className="text-gray-900 break-words">{detail.zone_name || 'N/A'}</div>
                                <div className="text-gray-900 break-words">{detail.ward_name || 'N/A'}</div>
                                <div className="text-center">
                                <button
                                    onClick={(e) => handleCompletedClick(detail, e)}
                                    className="text-green-600 font-medium hover:text-green-700 transition-colors hover:underline"
                                  >
                                    {detail.completed || '0'}
                                  </button>
                                </div>
                                <div className="text-center text-blue-600 font-medium">
                                  {detail.pending || '0'}
                                </div>
                                <div className="text-center text-gray-900 font-medium">
                                  {detail.total || '0'}
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Asset Details Modal */}
      <AssetDetailsModal 
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedWardInfo(null);
          setAssetDetails([]);
        }}
        assetDetails={assetDetails}
        isLoading={isLoadingAssetDetails}
        wardInfo={selectedWardInfo}
        onAssetClick={handleAssetClick}
      />

    </div>
  );
};