import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Building, Pencil, X, ArrowLeft, 
  MapPin, User, Camera, Tag, Home
} from 'lucide-react';
import api from '../apiConfig/api';

const ImageThumbnail = React.memo(({ image, index, onClick }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const imageUrl = useMemo(() => {
    if (!image?.path) return null;
    const userData = JSON.parse(localStorage.getItem('userData'));
    // Add auth token as query parameter
    return `https://ras-tndma.in/api/prod/uploads/survey/${image.path}?token=${userData?.authToken}`;
  }, [image]);

  if (!imageUrl) return null;

  return (
    <div 
      className="aspect-square rounded-lg overflow-hidden border border-gray-200 cursor-pointer relative bg-gray-50"
      onClick={onClick}
    >
      {isLoading && !hasError && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-6 h-6 border-2 border-blue-600 border-t-transparent rounded-full animate-spin" />
        </div>
      )}
      <img
        src={imageUrl}
        alt={`Property ${index + 1}`}
        className={`w-full h-full object-cover transition-all duration-300 ${
          isLoading || hasError ? 'opacity-0' : 'opacity-100 hover:scale-105'
        }`}
        onLoad={() => setIsLoading(false)}
        onError={() => {
          setIsLoading(false);
          setHasError(true);
        }}
      />
      {hasError && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <Camera className="h-8 w-8 text-gray-400" />
        </div>
      )}
    </div>
  );
});

const ImageGallery = React.memo(({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  
  const imageArray = useMemo(() => {
    try {
      // Handle string input
      const parsed = typeof images === 'string' ? JSON.parse(images) : images;
      
      // Handle array of objects with path property
      if (Array.isArray(parsed)) {
        return parsed.map(item => {
          if (typeof item === 'string') {
            return { path: item };
          }
          return item;
        });
      }
      
      // Handle array-like object
      if (parsed && typeof parsed === 'object') {
        return Object.values(parsed).map(item => {
          if (typeof item === 'string') {
            return { path: item };
          }
          return item;
        });
      }
      
      return [];
    } catch (error) {
      console.error('Failed to parse images:', error);
      return [];
    }
  }, [images]);

  if (!imageArray?.length) return null;

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {imageArray.map((image, index) => (
        <ImageThumbnail
          key={`${image.path}-${index}`}
          image={image}
          index={index}
          onClick={() => setSelectedImage(image)}
        />
      ))}

      {selectedImage && (
        <div 
          className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-4"
          onClick={() => setSelectedImage(null)}
        >
          <div className="relative max-w-4xl w-full">
            <ImageThumbnail
              image={selectedImage}
              index={imageArray.indexOf(selectedImage)}
              onClick={(e) => e.stopPropagation()}
            />
            <button 
              onClick={(e) => {
                e.stopPropagation();
                setSelectedImage(null);
              }}
              className="absolute top-4 right-4 p-2 bg-white rounded-full hover:bg-gray-100"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
});
const InfoCard = ({ icon: Icon, title, children }) => (
  <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-4">
    <div className="flex items-center gap-3 mb-3">
      <div className="bg-blue-50 p-2 rounded-lg">
        <Icon className="h-5 w-5 text-blue-600" />
      </div>
      <h3 className="font-semibold text-gray-900">{title}</h3>
    </div>
    {children}
  </div>
);

const DetailRow = ({ label, value }) => (
  <div className="py-2">
    <div className="text-sm text-gray-500">{label}</div>
    <div className="font-medium text-gray-900">{value || 'N/A'}</div>
  </div>
);

const AssetReport = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [assetData, setAssetData] = useState(null);

  useEffect(() => {
    const fetchAssetDetails = async () => {
      try {
        setIsLoading(true);
        const response = await api.get(`/asset/report/${id}`);
        setAssetData(response.data.data);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAssetDetails();
  }, [id]);

  const ownerDetails = useMemo(() => {
    try {
      return assetData?.owner_det ? JSON.parse(assetData.owner_det) : null;
    } catch (error) {
      console.error('Error parsing owner details:', error);
      return null;
    }
  }, [assetData?.owner_det]);

  const handleRequestEdit = () => {
    navigate(`/verify/${id}`, { state: assetData });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 mt-16">
      <div className="max-w-7xl mx-auto px-4">
        <header className="sticky top-0 z-50 backdrop-blur-md bg-white/90 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 p-4">
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate(-1)}
                className="p-2 text-gray-600 hover:text-gray-900 transition-colors"
              >
                <ArrowLeft className="h-5 w-5" />
              </button>
              <div className="flex items-center gap-3">
                <Tag className="h-5 w-5 text-blue-600" />
                <span className="text-sm font-medium text-gray-600">#{assetData?.asst_ref}</span>
              </div>
            </div>
            <button
              onClick={handleRequestEdit}
              className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
            >
              <Pencil className="h-4 w-4" />
              Request Edit
            </button>
          </div>
        </header>

        <main className="py-4 grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
          <InfoCard icon={Building} title="Organization Details">
            <DetailRow label="Organization Name" value={assetData?.org_name} />
            <DetailRow label="Zone" value={assetData?.zone_name} />
            <DetailRow label="Ward" value={assetData?.ward_name} />
          </InfoCard>

          <InfoCard icon={User} title="Owner Details">
            <DetailRow label="Owner Name" value={assetData?.owner} />
            <DetailRow label="Mobile Number" value={ownerDetails?.mobile} />
          </InfoCard>

          <InfoCard icon={Home} title="Property Details">
            <DetailRow label="Usage Type" value={assetData?.usage} />
            <DetailRow label="Build Area (as per Record)" value={`${assetData?.area || 'N/A'} sq.ft`} />
            <DetailRow label="Build Area (as Observed)" value={`${assetData?.build_area || 'N/A'} sq.ft`} />
          </InfoCard>

          <InfoCard icon={MapPin} title="Location Details">
            <DetailRow label="Door Number" value={assetData?.new_door} />
            <DetailRow label="Street Name" value={assetData?.street_name} />
          </InfoCard>

          {assetData?.images && (
            <div className="md:col-span-2 xl:col-span-4">
              <InfoCard icon={Camera} title="Property Images">
                <div className="mt-3">
                  <ImageGallery images={assetData.images} />
                </div>
              </InfoCard>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default AssetReport;