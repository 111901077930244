import React from 'react';
import { Building, ArrowRight } from 'lucide-react';
import LoadingText from './loadingtext';

const WelcomeHeader = ({
  userName,
  isLoading,
  userData,
  wardData,
  handleStartSurvey
}) => {
  const BaseHeader = () => (
    <div className="px-6 py-4">
      <h1 className="text-2xl font-bold text-gray-800 mb-1">
        Welcome {userName}
      </h1>
    </div>
  );

  const HeaderContent = () => {
    // Show different content based on organization type
    if (userData.orgCode === "CMA") {
      return (
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
          <div className="flex items-center gap-4">
            <div className="p-2 bg-white/10 rounded-lg backdrop-blur-sm">
              <Building className="h-5 w-5 text-black" />
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="text-lg text-black font-semibold">
                {userData.organizationName || 'COMMISSIONERATE OF MUNICIPAL ADMINISTRATION'}
              </h2>
              {userData.designation && (
                <div className="flex items-center">
                  {isLoading ? (
                    <LoadingText width="w-32" height="h-4" />
                  ) : (
                    <p className="text-sm text-black/80">
                      {userData.designation}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          
          {wardData?.length > 0 && (
            <button 
              onClick={handleStartSurvey}
              disabled={isLoading}
              className="flex items-center gap-2 px-4 py-2 bg-white text-[#041013] rounded-lg 
                transition-all duration-200 font-medium text-sm whitespace-nowrap
                hover:bg-white/90 hover:shadow-md"
            >
              Start Survey
              <ArrowRight className="h-4 w-4" />
            </button>
          )}
        </div>
      );
    }

    return (
      <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
        <div className="flex items-center gap-4">
          <div className="p-2 bg-white/10 rounded-lg backdrop-blur-sm">
            <Building className="h-5 w-5 text-black" />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center">
              {isLoading ? (
                <LoadingText width="w-64" height="h-6" />
              ) : (
                <h2 className="text-lg text-black">
                  <span className="font-semibold">
                    {userData.organizationName || 'Organization'}
                  </span>
                  {userData.zoneName && (
                    <span className="font-normal"> &gt;&gt; {userData.zoneName}</span>
                  )}
                </h2>
              )}
            </div>
            {userData.designation && (
              <div className="flex items-center">
                {isLoading ? (
                  <LoadingText width="w-32" height="h-4" />
                ) : (
                  <p className="text-sm text-black/80">
                    {userData.designation}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>

        {wardData?.length > 0 && (
          <button 
            onClick={handleStartSurvey}
            disabled={isLoading}
            className="flex items-center gap-2 px-4 py-2 bg-white text-[#041013] rounded-lg 
              transition-all duration-200 font-medium text-sm whitespace-nowrap
              hover:bg-white/90 hover:shadow-md"
          >
            Start Survey
            <ArrowRight className="h-4 w-4" />
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white shadow-xl overflow-hidden mb-6">
      <BaseHeader />
      <div className="bg-gradient-to-r from-[#75d1e3] to-[#66dbf3] px-6 py-4">
        <HeaderContent />
      </div>
    </div>
  );
};

export default WelcomeHeader;