import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipboardCheck, ClipboardList, Calendar, Building, ArrowRight, AlertCircle, X, ChevronRight } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import { useSurvey } from '../context/surveycontext';
import api from '../apiConfig/api';
import { UnauthorizedSurveyTable } from '../components/unauthorizedtable';
import AssetDetailsSection from '../components/assetdetails';
import { OrgSummaryTable } from '../components/org_summary';
import WelcomeHeader from '../components/welcomeheader';
import StatsCards from '../components/statscard';
import SurveyBarChart from '../components/surveychart';
const LoadingText = ({ width = "w-40", height = "h-6" }) => (
  <div className={`inline-block ${width} ${height} animate-pulse bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 rounded relative overflow-hidden`}>
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent animate-shimmer" />
  </div>
);

const LoadingValue = () => (
  <span className="animate-pulse">
    <span className="h-8 w-16 bg-gray-200 rounded inline-block"></span>
  </span>
);

const ErrorMessage = ({ message }) => (
  <div className="flex items-center gap-2 text-orange-600 bg-orange-50 p-3 rounded-lg">
    <AlertCircle className="h-5 w-5" />
    <span>{message}</span>
  </div>
);

const LoadingItem = () => (
  <div className="grid grid-cols-5 gap-4 py-3">
    <div className="col-span-2 space-y-2">
      <div className="h-4 bg-gray-200 rounded w-32"></div>
      <div className="h-3 bg-gray-200 rounded w-24"></div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-200 rounded w-12"></div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-200 rounded w-12"></div>
    </div>
    <div className="flex justify-center">
      <div className="h-4 bg-gray-200 rounded w-12"></div>
    </div>
  </div>
);

const CompletedSurveyHeader = () => (
  <div className="hidden lg:grid grid-cols-5 gap-4 py-3 border-b border-gray-200 bg-gray-50 rounded-t-lg px-4">
    <div className="col-span-2">
      <h4 className="text-sm font-medium text-gray-600">Ward Details</h4>
    </div>
    <div className="text-center">
      <h4 className="text-sm font-medium text-gray-600">Completed</h4>
    </div>
    <div className="text-center">
      <h4 className="text-sm font-medium text-gray-600">Pending</h4>
    </div>
    <div className="text-center">
      <h4 className="text-sm font-medium text-gray-600">Total</h4>
    </div>
  </div>
);

const CompletedSurveyItem = ({ report, onCompletedClick }) => (
  <div className="flex flex-col lg:grid lg:grid-cols-5 gap-4 py-3 border-b border-gray-100 last:border-0 px-4 hover:bg-gray-50">
    <div className="col-span-2">
      <h3 className="font-medium text-gray-800">{report.ward_name}</h3>
      <div className="text-xs text-gray-500 mt-1">
        <p>{report.org_name}</p>
        <p>{report.zone_name}</p>
      </div>
    </div>
    <div className="flex justify-between lg:justify-center items-center">
      <span className="text-sm text-gray-500 lg:hidden">Completed:</span>
      <button
        onClick={() => onCompletedClick(report)}
        className="font-medium text-green-600 hover:text-green-700 transition-colors hover:underline"
      >
        {report.completed || '0'}
      </button>
    </div>
    <div className="flex justify-between lg:justify-center items-center">
      <span className="text-sm text-gray-500 lg:hidden">Pending:</span>
      <span className="font-medium text-blue-600">{report.pending || '0'}</span>
    </div>
    <div className="flex justify-between lg:justify-center items-center">
      <span className="text-sm text-gray-500 lg:hidden">Total:</span>
      <span className="font-medium text-gray-700">{report.total || '0'}</span>
    </div>
  </div>
);

const AssetDetailsModal = ({ isOpen, onClose, assetDetails, isLoading, wardInfo, onAssetClick }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-3xl max-h-[90vh] flex flex-col">
        <div className="p-4 border-b border-gray-200 flex items-center justify-between sticky top-0 bg-white">
          <h2 className="text-lg font-semibold text-gray-800">
            Asset Details - {wardInfo?.ward_name || 'N/A'} - Survey Completed
          </h2>
          <button 
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>
        <div className="overflow-auto p-4">
          {isLoading ? (
            <div className="space-y-4">
              {[1, 2, 3].map((i) => (
                <div key={i} className="animate-pulse space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-1/3"></div>
                  <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                </div>
              ))}
            </div>
          ) : assetDetails.length > 0 ? (
            <div className="grid gap-4">
              {assetDetails.map((asset, index) => (
                <div 
                  key={index} 
                  onClick={() => onAssetClick(asset.id)}
                  className="border border-gray-200 rounded-lg p-4 hover:shadow-lg hover:border-gray-300 transition-all cursor-pointer"
                >
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500">Street Name</p>
                      <p className="font-medium">{asset.street_name || 'N/A'}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Door No</p>
                      <p className="font-medium">{asset.new_door || 'N/A'}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Owner Name</p>
                      <p className="font-medium">{asset.owner || 'N/A'}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Usage</p>
                      <p className={`font-medium ${
                        asset.usage?.toLowerCase() === 'commercial' || 
                        asset.usage?.toLowerCase() === 'mixed' 
                          ? 'text-red-600' 
                          : 'text-gray-800'
                      }`}>
                        {asset.usage || 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 flex justify-end text-sm text-blue-600">
                    View Details <ChevronRight className="h-4 w-4" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-8 text-gray-500">
              No asset details available
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


const HomePage = () => {
  const navigate = useNavigate();
  const { setIsSurveyEnabled, setSurveyParams } = useSurvey();
  const dataFetchedRef = useRef(false);
  const [userName, setUserName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [globalError, setGlobalError] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [assetReports, setAssetReports] = useState([]);
  const [surveyDate, setSurveyDate] = useState('');
  const [proApiData, setProApiData] = useState(null);  // Add this line
  const [userData, setUserData] = useState({
    designation: '',
    organizationName: '',
    userId: '',
    orgId: '',
    zoneName: '',
    zoneId: '',
    orgCode: ''
  });
  const [surveyStats, setSurveyStats] = useState({
    totalCompleted: 0,
    totalPending: 0,
    monthlyCount: 0
  });
  const [wardData, setWardData] = useState([]);
  const [dailySurveyData, setDailySurveyData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWard, setSelectedWard] = useState(null);
  const [assetDetails, setAssetDetails] = useState([]);
  const [isLoadingAssetDetails, setIsLoadingAssetDetails] = useState(false);

  const getHomeData = async () => {
    try {
      const homeDataResponse = await api.get('/user/homedata');
      
      if (homeDataResponse?.data?.user) {
        const user = homeDataResponse.data.user;
        const headerInfo = {
          name: user.name || '',
          role: user.role || 'No designation',
          designation: user.role || 'No designation',
          organizationName: user.resp?.OrgName || 'Organization'
        };
        localStorage.setItem('headerInfo', JSON.stringify(headerInfo));
        
        setUserName(user.name || '');
        setSurveyDate(homeDataResponse?.data?.data?.[0]?.created || new Date().toISOString());
        
        return {
          mobile: user.mobile,
          role: user.role || 'No designation'
        };
      }
    } catch (error) {
      console.error('Home Data API Error:', error);
      setGlobalError('Failed to load user data');
    }
    return null;
  };

  const handleAssetClick = (assetId) => {
    if (!assetId) return;
    navigate(`/report/${assetId}`);
  };

  const handleCompletedClick = async (wardInfo) => {
    try {
      setIsLoadingAssetDetails(true);
      setSelectedWard(wardInfo);
      setIsModalOpen(true);

      const params = new URLSearchParams({
        org: wardInfo.org_name,
        zone: wardInfo.zone_name,
        ward: wardInfo.ward_name
      });

      const response = await api.get(`/asset/report?${params}`);
      setAssetDetails(response.data.data || []);
    } catch (error) {
      console.error('Error fetching asset details:', error);
      setAssetDetails([]);
    } finally {
      setIsLoadingAssetDetails(false);
    }
  };

  const fetchData = async () => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    
    try {
      setIsLoading(true);
      setGlobalError(null);
  
      const homeData = await getHomeData();
      if (!homeData?.mobile) {
        setIsLoading(false);
        return;
      }
  
      try {
        const userResponse = await api.get(`/user/proapi?mob=${homeData.mobile}`);
        const apiData = userResponse?.data?.data;
  
        if (!apiData) {
          throw new Error('No user data received');
        }
  
        setProApiData(apiData);
        localStorage.setItem('org_id', apiData?.ULBDetails?.[0]?.OrgId || '');
  
        // CMA Flow
        if (apiData?.ULBDetails?.some(detail => detail.OrgCode === "CMA")) {
          localStorage.setItem('org_code', 'CMA');
          localStorage.setItem('organization_name', 'COMMISSIONERATE OF MUNICIPAL ADMINISTRATION');
          localStorage.setItem('user_id', apiData.UserID || '');
          localStorage.setItem('org_id', apiData?.ULBDetails?.[0]?.OrgId || '');
  
          const orgResponse = await api.get('/user/all-org');
          const orgData = orgResponse?.data?.data || [];
          
          setAssetReports(orgData);
          const totals = orgData.reduce((acc, org) => ({
            totalCompleted: acc.totalCompleted + (parseInt(org.completed) || 0),
            totalPending: acc.totalPending + (parseInt(org.pending) || 0)
          }), { totalCompleted: 0, totalPending: 0 });
  
          setSurveyStats({
            totalCompleted: totals.totalCompleted,
            totalPending: totals.totalPending,
            monthlyCount: totals.totalCompleted
          });
  
          const designation = apiData.DesignationName || apiData.Designation || apiData?.ULBDetails?.[0]?.Designation || '';
  
          setUserData(prevData => ({
            ...prevData,
            userId: apiData.UserID || '',
            orgId: apiData?.ULBDetails?.[0]?.OrgId || '',
            orgCode: "CMA",
            organizationName: "COMMISSIONERATE OF MUNICIPAL ADMINISTRATION",
            designation: designation
          }));
  
          setIsLoading(false);
          return;
        }
  
        localStorage.setItem('organization_name', apiData.ULBDetails?.[0]?.OrgName || '');
        localStorage.setItem('organization_code', apiData.ULBDetails?.[0]?.OrgCode || '');
        localStorage.setItem('zone_name', apiData.zone?.[0]?.ZoneName || '');
        localStorage.setItem('user_id', apiData.UserID || '');
        
        // Store ward details if available
        if (apiData.ward && apiData.ward.length > 0) {
          const wardDetails = apiData.ward.map(ward => ({
            wardId: ward.WardID,
            wardName: ward.WardName
          }));
          localStorage.setItem('ward_details', JSON.stringify(wardDetails));
        } else {
          localStorage.removeItem('ward_details');
        }
  
        // Check if ward data exists
        if (apiData.ward && apiData.ward.length > 0) {
          const wardIds = apiData.ward.map(ward => ward.WardID).join(',');
          const wardSummaryResponse = await api.get(`/user/asst_smry?ward_ids=${wardIds}`);
          const wardSummaryData = wardSummaryResponse?.data?.data || [];
  
          setAssetReports(wardSummaryData);
          const totals = wardSummaryData.reduce((acc, ward) => ({
            totalCompleted: acc.totalCompleted + (parseInt(ward.completed) || 0),
            totalPending: acc.totalPending + (parseInt(ward.pending) || 0)
          }), { totalCompleted: 0, totalPending: 0 });
  
          setSurveyStats({
            totalCompleted: totals.totalCompleted,
            totalPending: totals.totalPending,
            monthlyCount: totals.totalCompleted
          });
  
          setWardData(apiData.ward);
          setSurveyParams({
            wardData: apiData.ward,
            orgId: apiData.ULBDetails?.[0]?.OrgId,
            userId: apiData.UserID,
            organizationName: apiData.ULBDetails?.[0]?.OrgName
          });
          setIsSurveyEnabled(true);
  
          setUserData(prevData => ({
            ...prevData,
            userId: apiData.UserID,
            orgId: apiData.ULBDetails?.[0]?.OrgId,
            orgCode: apiData.ULBDetails?.[0]?.OrgCode,
            organizationName: apiData.ULBDetails?.[0]?.OrgName,
            zoneName: apiData.zone?.[0]?.ZoneName || '',
            designation: apiData.DesignationName || ''
          }));
  
        } else {
          const orgName = apiData.ULBDetails?.[0]?.OrgName;
          if (orgName) {
            const summaryResponse = await api.get(`/user/asst_smry?org=${encodeURIComponent(orgName)}`);
            const summaryData = summaryResponse?.data?.data || [];
            setAssetReports(summaryData);
          }
  
          setUserData(prevData => ({
            ...prevData,
            userId: apiData.UserID,
            orgId: apiData.ULBDetails?.[0]?.OrgId,
            orgCode: apiData.ULBDetails?.[0]?.OrgCode,
            organizationName: apiData.ULBDetails?.[0]?.OrgName,
            designation: apiData.DesignationName || ''
          }));
        }
  
        try {
          const today = new Date().toISOString().split('T')[0];
          const dailyResponse = await api.get(`/daily_survey?date=${today}`);
          const dailyData = dailyResponse?.data?.data || [];
          setDailySurveyData(dailyData);
        } catch (error) {
          console.error('Daily Survey Data Error:', error);
          setDailySurveyData([]);
        }
  
      } catch (error) {
        console.error('API Error:', error);
        setGlobalError('Failed to fetch data');
        setAssetReports([{
          ward_name: 'No Data Available',
          completed: 0,
          pending: 0,
          total: 0,
          org_name: userData.organizationName || 'Organization',
          zone_name: 'N/A'
        }]);
        setSurveyStats({
          totalCompleted: 0,
          totalPending: 0,
          monthlyCount: 0
        });
      }
    } catch (error) {
      console.error('Error in fetchData:', error);
      setGlobalError(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    return () => {
      dataFetchedRef.current = false;
    };
  }, []);

  const handleStartSurvey = () => {
    navigate('/filters', { 
      state: { 
        wardData,             
        orgId: userData.orgId,        
        userId: userData.userId,      
        organizationName: userData.organizationName,
        zoneName: userData.zoneName,
        orgCode: userData.orgCode 
      }
    });
  };

  const renderUI = () => {
    if (userData.orgCode === "CMA") {
      return (
        <>
          <WelcomeHeader 
            userName={userName}
            isLoading={isLoading}
            userData={userData}
            wardData={wardData}
            handleStartSurvey={handleStartSurvey}
          />
          <OrgSummaryTable 
            data={assetReports} 
            isLoading={isLoading} 
            onNavigateToReport={handleAssetClick}
          />
        </>
      );
    }
  
    if (wardData?.length > 0) {
      return (
        <>
          <WelcomeHeader 
            userName={userName}
            isLoading={isLoading}
            userData={userData}
            wardData={wardData}
            handleStartSurvey={handleStartSurvey}
          />
          <StatsCards isLoading={isLoading} surveyStats={surveyStats} />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
            <AssetDetailsSection 
              isLoading={isLoading}
              assetReports={assetReports}
              onCompletedClick={handleCompletedClick}
            />
            <SurveyBarChart
              isLoading={isLoading}
              dailySurveyData={dailySurveyData}
              assetReports={assetReports}
            />
          </div>
        </>
      );
    }
    
    return (
      <>
        <WelcomeHeader 
          userName={userName}
          isLoading={isLoading}
          userData={userData}
          wardData={wardData}
          handleStartSurvey={handleStartSurvey}
        />
        <UnauthorizedSurveyTable 
          isLoading={isLoading}
          assetReports={assetReports}
          onCompletedClick={handleCompletedClick}
        />
      </>
    );
  };
  
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {globalError && (
          <div className="mb-4">
            <ErrorMessage message={globalError} />
          </div>
        )}
        {renderUI()}
        <AssetDetailsModal 
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          assetDetails={assetDetails}
          isLoading={isLoadingAssetDetails}
          wardInfo={selectedWard}
          onAssetClick={handleAssetClick}
        />
      </div>
    </div>
  );
};
export default HomePage;